<template>
  <svg viewBox="0 0 1010 503"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <g ref="dots"
       class="dots"
    >
      <path id="Vector34"
            d="M214 92.8274C214 94.4842 215.343 95.8274 217 95.8274C218.657 95.8274 220 94.4842 220 92.8274C220 91.1705 218.657 89.8274 217 89.8274C215.343 89.8274 214 91.1705 214 92.8274Z"
            fill="#FFD200"
      />
      <path id="Vector33"
            d="M222 136C222 137.657 223.343 139 225 139C226.657 139 228 137.657 228 136C228 134.343 226.657 133 225 133C223.343 133 222 134.343 222 136Z"
            fill="#FFD200"
      />
      <path id="Vector32"
            d="M184 158C184 159.657 185.343 161 187 161C188.657 161 190 159.657 190 158C190 156.343 188.657 155 187 155C185.343 155 184 156.343 184 158Z"
            fill="#FFD200"
      />
      <path id="Vector31"
            d="M118 100C118 101.657 119.343 103 121 103C122.657 103 124 101.657 124 100C124 98.3431 122.657 97 121 97C119.343 97 118 98.3431 118 100Z"
            fill="#FFD200"
      />
      <path id="Vector30"
            d="M36 63C36 64.6569 37.3431 66 39 66C40.6569 66 42 64.6569 42 63C42 61.3431 40.6569 60 39 60C37.3431 60 36 61.3431 36 63Z"
            fill="#FFD200"
      />
      <path id="Vector29"
            d="M155 136C155 137.657 156.343 139 158 139C159.657 139 161 137.657 161 136C161 134.343 159.657 133 158 133C156.343 133 155 134.343 155 136Z"
            fill="#FFD200"
      />
      <path id="Vector28"
            d="M124 173C124 174.657 125.343 176 127 176C128.657 176 130 174.657 130 173C130 171.343 128.657 170 127 170C125.343 170 124 171.343 124 173Z"
            fill="#FFD200"
      />
      <path id="Vector27"
            d="M89 145C89 146.657 90.3431 148 92 148C93.6569 148 95 146.657 95 145C95 143.343 93.6569 142 92 142C90.3431 142 89 143.343 89 145Z"
            fill="#FFD200"
      />
      <path id="Vector26"
            d="M132 78C132 79.6569 133.343 81 135 81C136.657 81 138 79.6569 138 78C138 76.3431 136.657 75 135 75C133.343 75 132 76.3431 132 78Z"
            fill="#FFD200"
      />
      <path id="Vector25"
            d="M251 344C251 345.657 252.343 347 254 347C255.657 347 257 345.657 257 344C257 342.343 255.657 341 254 341C252.343 341 251 342.343 251 344Z"
            fill="#FFD200"
      />
      <path id="Vector24"
            d="M221 329C221 330.657 222.343 332 224 332C225.657 332 227 330.657 227 329C227 327.343 225.657 326 224 326C222.343 326 221 327.343 221 329Z"
            fill="#FFD200"
      />
      <path id="Vector23"
            d="M288 329C288 330.657 289.343 332 291 332C292.657 332 294 330.657 294 329C294 327.343 292.657 326 291 326C289.343 326 288 327.343 288 329Z"
            fill="#FFD200"
      />
      <path id="Vector22"
            d="M243 411C243 412.657 244.343 414 246 414C247.657 414 249 412.657 249 411C249 409.343 247.657 408 246 408C244.343 408 243 409.343 243 411Z"
            fill="#FFD200"
      />
      <path id="Vector21"
            d="M273 389C273 390.657 274.343 392 276 392C277.657 392 279 390.657 279 389C279 387.343 277.657 386 276 386C274.343 386 273 387.343 273 389Z"
            fill="#FFD200"
      />
      <path id="Vector20"
            d="M532 367C532 368.657 533.343 370 535 370C536.657 370 538 368.657 538 367C538 365.343 536.657 364 535 364C533.343 364 532 365.343 532 367Z"
            fill="#FFD200"
      />
      <path id="Vector19"
            d="M502 329C502 330.657 503.343 332 505 332C506.657 332 508 330.657 508 329C508 327.343 506.657 326 505 326C503.343 326 502 327.343 502 329Z"
            fill="#FFD200"
      />
      <path id="Vector18"
            d="M546 322C546 323.657 547.343 325 549 325C550.657 325 552 323.657 552 322C552 320.343 550.657 319 549 319C547.343 319 546 320.343 546 322Z"
            fill="#FFD200"
      />
      <path id="Vector17"
            d="M509 395C509 396.657 510.343 398 512 398C513.657 398 515 396.657 515 395C515 393.343 513.657 392 512 392C510.343 392 509 393.343 509 395Z"
            fill="#FFD200"
      />
      <path id="Vector16"
            d="M886 389C886 390.657 887.343 392 889 392C890.657 392 892 390.657 892 389C892 387.343 890.657 386 889 386C887.343 386 886 387.343 886 389Z"
            fill="#FFD200"
      />
      <path id="Vector15"
            d="M983 455C983 456.657 984.343 458 986 458C987.657 458 989 456.657 989 455C989 453.343 987.657 452 986 452C984.343 452 983 453.343 983 455Z"
            fill="#FFD200"
      />
      <path id="Vector14"
            d="M909 433C909 434.657 910.343 436 912 436C913.657 436 915 434.657 915 433C915 431.343 913.657 430 912 430C910.343 430 909 431.343 909 433Z"
            fill="#FFD200"
      />
      <path id="Vector13"
            d="M938 404C938 405.657 939.343 407 941 407C942.657 407 944 405.657 944 404C944 402.343 942.657 401 941 401C939.343 401 938 402.343 938 404Z"
            fill="#FFD200"
      />
      <path id="Vector12"
            d="M835 404C835 405.657 836.343 407 838 407C839.657 407 841 405.657 841 404C841 402.343 839.657 401 838 401C836.343 401 835 402.343 835 404Z"
            fill="#FFD200"
      />
      <path id="Vector11"
            d="M872 366C872 367.657 873.343 369 875 369C876.657 369 878 367.657 878 366C878 364.343 876.657 363 875 363C873.343 363 872 364.343 872 366Z"
            fill="#FFD200"
      />
      <path id="Vector10"
            d="M805 100C805 101.657 806.343 103 808 103C809.657 103 811 101.657 811 100C811 98.3431 809.657 97 808 97C806.343 97 805 98.3431 805 100Z"
            fill="#FFD200"
      />
      <path id="Vector9"
            d="M753 129C753 130.657 754.343 132 756 132C757.657 132 759 130.657 759 129C759 127.343 757.657 126 756 126C754.343 126 753 127.343 753 129Z"
            fill="#FFD200"
      />
      <path id="Vector8"
            d="M865 166C865 167.657 866.343 169 868 169C869.657 169 871 167.657 871 166C871 164.343 869.657 163 868 163C866.343 163 865 164.343 865 166Z"
            fill="#FFD200"
      />
      <path id="Vector7"
            d="M790 181C790 182.657 791.343 184 793 184C794.657 184 796 182.657 796 181C796 179.343 794.657 178 793 178C791.343 178 790 179.343 790 181Z"
            fill="#FFD200"
      />
      <path id="Vector6"
            d="M590 85C590 86.6569 591.343 88 593 88C594.657 88 596 86.6569 596 85C596 83.3431 594.657 82 593 82C591.343 82 590 83.3431 590 85Z"
            fill="#FFD200"
      />
      <path id="Vector5"
            d="M568 160C568 161.657 569.343 163 571 163C572.657 163 574 161.657 574 160C574 158.343 572.657 157 571 157C569.343 157 568 158.343 568 160Z"
            fill="#FFD200"
      />
      <path id="Vector4"
            d="M525 70C525 71.6569 526.343 73 528 73C529.657 73 531 71.6569 531 70C531 68.3431 529.657 67 528 67C526.343 67 525 68.3431 525 70Z"
            fill="#FFD200"
      />
      <path id="Vector3"
            d="M361 41C361 42.6569 362.343 44 364 44C365.657 44 367 42.6569 367 41C367 39.3431 365.657 38 364 38C362.343 38 361 39.3431 361 41Z"
            fill="#FFD200"
      />
      <path id="Vector2"
            d="M472 122C472 123.657 473.343 125 475 125C476.657 125 478 123.657 478 122C478 120.343 476.657 119 475 119C473.343 119 472 120.343 472 122Z"
            fill="#FFD200"
      />
      <path id="Vector1"
            d="M509 106C509 107.657 510.343 109 512 109C513.657 109 515 107.657 515 106C515 104.343 513.657 103 512 103C510.343 103 509 104.343 509 106Z"
            fill="#FFD200"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { animate } from 'motion';

const dots = ref();
const usedIntegers = ref(new Set());
const interval = ref();

onMounted(() => {
  interval.value = setInterval(animateDots, 500);
});

const animateDots = () => {
  if (!dots.value) return;

  const randomInt = randomInteger(0, dots.value.children.length);
  if (usedIntegers.value.has(randomInt)) return;
  else usedIntegers.value.add(randomInt);

  animate(
    dots.value.children[randomInt],
    { opacity: [0, 1, 0], scale: [0.5, 2, 0.5] },
    { duration: 4, easing: ['ease-in', 'linear', 'ease-out'] }
  ).finished.then(() => {
    usedIntegers.value.delete(randomInt);
  });
};

onUnmounted(() => {
  clearInterval(interval.value);
});

const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
</script>
<style>
.dots path {
  opacity: 0;
  transform-origin: center;
  transform-box: fill-box;
}
</style>
